import { connect } from 'react-redux';
import React, {useEffect, useState} from 'react';
import { getAllLicense, showWindow } from "domain/actions/license.action";
import AddLicense from './addLicense';
import ViewingLicenseDialog from './viewingLicense.window';
import { HeaderPage, EmptyPage } from 'pages/views/components';
import ContentPage from './content';
import { WrapperSpinner } from 'components/static/wrapperSpinner';
import { Button } from 'primereact/button';

const License: React.FC = (props: any) => {
  const [ load, setLoad ] = useState(false);
  const [ currentId, setCurrentId ] = useState<string | null>(null);
  const { getAllLicense, showWindow, licenses } = props;

  const handleRefreshLicense = () => getAllLicense();
  const handleViewing = (id: string) => {
    setCurrentId(id)
    showWindow('edit', true);
  }

  useEffect(()=>getAllLicense(), [getAllLicense])
  useEffect(()=> {setTimeout(() => setLoad(true), 600)}, [])

  const handleAdd = () => showWindow('add', true);
  const handleRefresh = () => getAllLicense();

  return (
    <div className="License">
      <HeaderPage header="Настройки" description="Лицензии"/>

      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-ai-baseline">
        <div className="p-d-flex p-col-12 p-md-4 p-xl-3">
          {/* <Button label="Добавить" onClick={handleAdd} className="button-primary-out p-mr-2" /> */}
          <Button label="Обновить" onClick={handleRefresh} className="button-primary-out p-mr-2"/>
        </div>
      </div>

      <WrapperSpinner progress={!load} >
        <EmptyPage 
          isEmpty={!licenses.length}
          header="Список лицензий пуст" 
          description="Лицензии необходимы для привязки устройства в приложении Android - Создайте новую лицензию"
        >
          <ContentPage 
            handleAddLicense={()=>showWindow('add', true)}
            handleViewingLicense={handleViewing}
            handleRefreshLicense={()=>handleRefreshLicense()}
            licenses={licenses} 
          />
        </EmptyPage>
      </WrapperSpinner>

      <AddLicense />
      <ViewingLicenseDialog currentId={currentId}/>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  licenses: state.license.license,
  request: state.license.request, 
});

export default connect(mapStateToProps, {getAllLicense, showWindow})(License);