import React from 'react';
import { LogoHeader } from 'components/static';
import { Contacts } from './contacts';

export const Header: React.FC = () => {

  return (
    <div className="greeting-header">
      
      <LogoHeader />

      <Contacts />
      
    </div>
  );
};