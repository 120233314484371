import { useContext, createContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from 'domain/reducers';

type AuthContextProps = {
  children: any
};

interface IAuthContextProps {
  isAuthenticated: boolean;
}
export const authContext = createContext({} as IAuthContextProps);

export const ProvideAuth = ({ children }: AuthContextProps) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  return {
    isAuthenticated,
  };
}
