import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { PrivateRoute } from "pages/routes/config/TypeRoute";

import { Header, SidebarComponent } from 'pages/views/components';
import { signOut, getAccountMe } from "domain/actions/auth.action";

interface IPrivateProps{
  signOut: () => void;
  getAccountMe: () => void;
  account: object,
  currentUser: object,
}

export const PrivateMain: React.FC<any> = (props: IPrivateProps) => {
  const { signOut, getAccountMe, account, currentUser } = props;

  const navigate = useNavigate();
  const [ visSidebar, setVisSidebar ] = useState(true);
  const [ toggled, setToggle ] = useState(false);

  const handleLogotype = () => navigate("/")
  const handleLogout = () => signOut();
  
  const handleSidebar = () => {
    setVisSidebar(!toggled ? false : !visSidebar)
    setToggle(true)
  };
  const handleToggleSidebar = () => {
    setVisSidebar(true)
    setToggle(!toggled)
  }

  useEffect(()=>getAccountMe(),[getAccountMe])

  return (
    <PrivateRoute>
      <>
        <Header 
          handleSidebar = { handleSidebar }
          handleLogotype = { handleLogotype } 
          handleLogout = { handleLogout }
          currentUser={currentUser}
          account={account}
        />

        <div className="private-main">
          <SidebarComponent 
            collapsed={visSidebar}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
          />

          <div className="p-p-2 main-page">
            <Outlet />
          </div>
        </div>
      </>
    </PrivateRoute>
  );
};

const mapStateToProps = (state: any) => ({
  account: state.account.account,
  currentUser: state.account.currentUser
});

export default connect(mapStateToProps,{signOut, getAccountMe})(PrivateMain)