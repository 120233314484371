import axios from "axios";
import { AuthController } from "./Auth.controller";

class AMSController {
  private Auth: AuthController;

  constructor() {
    this.Auth = new AuthController();
  }

  private getBaseUrl(): any {
    const env = process.env;
    const prefix = ""
    return env.REACT_APP_AMS + prefix;
  }  

  private getHeaders(params?: any) {
    let token = this.Auth.getTokenStorage();

    let headers = {
      timeout: 3000,
      headers: {
        "Content-Type": "application/json",
        "Authorization": ""
      },
      params: params,
      baseURL: this.getBaseUrl()
    };

    if(token) headers.headers.Authorization = `Bearer ${token}`;
    
    return headers;
  }

  async get(url: string, params?: any): Promise<any>{
    return await axios.get(url, this.getHeaders(params))
  }

  async post(url: string, body: object): Promise<any>{
    return await axios.post(url, body, this.getHeaders())
  }

  async put(url: string, body: object): Promise<any>{
    return await axios.put(url, body, this.getHeaders())
  }

  async patch(url: string, body: object): Promise<any>{
    return await axios.patch(url, body, this.getHeaders())
  }

  async delete(url: string): Promise<any>{
    return await axios.delete(url, this.getHeaders())
  }
}

export default AMSController;