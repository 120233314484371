import { connect } from 'react-redux';
import React from 'react';
import { HeaderPage } from 'pages/views/components';

const Account: React.FC = (props: any) => {

  return (
    <div className="License">
      <HeaderPage header="Настройки" description="Настройки аккаунта"/> 
    </div>
  );
};

export default connect(null, null)(Account);