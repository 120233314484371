import { put, call, fork, takeEvery } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as actions from "domain/actions/bid.action";
import * as constants from "domain/constants/bid.constant";
import * as types from "domain/types/bid.type";


const api = new ApiController();

export async function getBidRequestsApi(): Promise<types.IBid[]> {
  return await api.get(`/regRequests`)
    .then( res => res.data )
    .catch( e => console.error(e));
}

export function* getAllBidRequests(): any{
  yield put(actions.reqBids(true))

  const bidRequests = yield call(getBidRequestsApi);
  const data = bidRequests ? bidRequests.data : []
  yield put(actions.setAllBidRequests(data))

  yield put(actions.reqBids(true))
}

export function* watchGetBidRequests() {
  yield takeEvery(constants.BID_REQUEST_ACTION_GET_BID_REQUEST, getAllBidRequests)
}

export default function* bidRequestsSagas() {
  yield fork(watchGetBidRequests)
}