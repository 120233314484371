import { all, fork } from 'redux-saga/effects'

import auth from 'domain/sagas/auth.saga';
import bids from 'domain/sagas/bid.saga';
import license from 'domain/sagas/license.saga';

export default function* rootSaga() {
  yield all(
    [
      fork(auth),
      fork(license),
      fork(bids)
    ])
}

