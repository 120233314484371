import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LogoHeader } from 'components/static';
import { confirmPopup } from "primereact/confirmpopup";

import styles from "./styles.module.css";

export interface IHeaderProps{
  handleSidebar: (event: React.MouseEvent<HTMLElement>) => void;
  handleLogout: () => void;
  handleLogotype: (event: React.MouseEvent<HTMLElement>) => void;
  currentUser: { name: string; roleTranslate: string;} | any;
  account: { name: string; } | any;
}

export const Header: React.FC<IHeaderProps> = (props) => {
  const { handleSidebar, handleLogout, handleLogotype, currentUser, account } = props;
  
  const confirmLogout = (event: { currentTarget: any; }) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Вы действительно хотите выйти?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => handleLogout(),
      reject: () => {},
    });
  };

  return (
    <div className={styles.header}>
      <div className={styles.header_button} onClick={handleSidebar}>
        <FontAwesomeIcon icon={["fas", "bars"]} />
      </div>

      <div className={styles.position}>
        <div className={styles.logo} onClick={handleLogotype}>
          <LogoHeader />  
        </div>

        <div className={styles.account}>
          {account && <span>{account.name}</span>}
        </div>

        <div className={styles.account}>
          {currentUser && (
            <>
              <span>{currentUser.lastName} {currentUser.firstName[0].toUpperCase()}.</span>
              <span className={styles.account_role}>{currentUser.roleTranslate}</span>
            </>
          )}
        </div>
      </div>

      <div className={styles.header_button} onClick={confirmLogout}>
        <i className="pi pi-sign-out" ></i>
      </div>
      
    </div>
  );
};

export default Header;