import React from 'react';

import styles from "./styles.module.css";

export const Logotype: React.FC = () => {
  return (
    <img src={process.env.PUBLIC_URL + "/logo512.png"} alt="VDQRCODE"  className={styles.logotype_image}/>
  );
};

export const ServiceName: React.FC = () => {
  return (
    <span className={styles.logotype_name}> VD.QR-CODE </span>
  );
};

export const LogoHeader: React.FC = () => {
  return (
    <div className={styles.logotype}>
      
      <Logotype />

      <ServiceName />
      
    </div>
  );
};