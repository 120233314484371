import { connect } from 'react-redux';
import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Field, withTypes } from "react-final-form";
import { TextField } from 'components/form/fields';
import { composeValidators, requiredValidator } from 'components/form/validators';
import { addLicense, showWindow } from 'domain/actions/license.action';

//form
export interface IAddLicenseForm {
  name: string;
}

const { Form } = withTypes<IAddLicenseForm>()

const AddLicenseDialog: React.FC = (props: any) => {
  const { visible, showWindow, addLicense } = props; 
  const data: IAddLicenseForm = {
    name: ""
  };

  const onSubmit = async (values: IAddLicenseForm) => { 
    addLicense(values)
  };

  const handleClose = () => showWindow('add', false);

  return (
    <Dialog header="Добавление лицензии" visible={visible} style={{ width: '50vw' }} onHide={handleClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="p-mt-2 p-mb-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="name"
                label="Наименование"
                render={TextField}
                settings={{
                  placeholder: "Введите наименование лицензии"
                }}
                icon={["fas", "pen"]}
              />
            </div>

            <div className="p-mt-1 p-d-flex p-jc-end">
              <Button 
                label="Создать" 
                type="submit" 
                disabled={submitting}
                className="button-success p-col-5 p-p-0" 
              />
            </div>
          </form>
        )}
      />      
    </Dialog>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.license.windows.add
})
  
export default connect(mapStateToProps, {addLicense, showWindow})(AddLicenseDialog);