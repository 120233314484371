import React from 'react';
import RoutesMap from "pages/routes";
import moment from "moment";
import { locale, addLocale } from "primereact/api";
import { dataPickerSetting } from "config/calendar";

import './assets/styles/index.css';
import './assets/styles/App.css';
import './assets/styles/main.css';
import './assets/styles/greeting.css';

import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.min.css";

import 'react-pro-sidebar/dist/css/styles.css';

import "assets/fontAwesome";
import 'moment/locale/ru';

moment.locale("ru");
addLocale("ru", dataPickerSetting);
locale("ru");

const App:React.FC = () => <RoutesMap />;

export default App;