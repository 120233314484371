export const LICENSE_ACTION = "LICENSE_ACTION";

export const LICENSE_ACTION_GET_LICENSE = "LICENSE_ACTION_GET_LICENSE";
export const LICENSE_ACTION_SET_LICENSE = "LICENSE_ACTION_SET_LICENSE";
export const LICENSE_ACTION_PATCH_LICENSE = "LICENSE_ACTION_PATCH_LICENSE";
export const LICENSE_ACTION_DELETE_LICENSE = "LICENSE_ACTION_DELETE_LICENSE";

export const LICENSE_ACTION_GET_LICENSE_BY_ID = "LICENSE_ACTION_GET_LICENSE_BY_ID";
export const LICENSE_REDUCE_SELECT_LICENSE = "LICENSE_REDUCE_SELECT_LICENSE";
export const LICENSE_ACTION_REGISTRY_LICENSE = "LICENSE_ACTION_REGISTRY_LICENSE";
export const LICENSE_REDUCE_IS_SHOW_WINDOW = "LICENSE_REDUCE_IS_SHOW_WINDOW";

export const LICENSE_REDUCE_SET_LICENSE = "LICENSE_REDUCE_SET_LICENSE";
export const PAGINATION_REDUCE_LICENSE = "PAGINATION_REDUCE_LICENSE";

export const LICENSE_REDUCE_ADD_LICENSE = "LICENSE_REDUCE_ADD_LICENSE";
export const LICENSE_ACTION_POST_LICENSE = "LICENSE_ACTION_POST_LICENSE";

export const CHECKOUT_REQUEST_LICENSE = "CHECKOUT_REQUEST_LICENSE";
export const CHECKOUT_REQUEST_ERROR_LICENSE = "CHECKOUT_REQUEST_ERROR_LICENSE";
export const CHECKOUT_REQUEST_SUCCESS_LICENSE = "CHECKOUT_REQUEST_SUCCESS_LICENSE";

