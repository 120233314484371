import { 
    LICENSE_ACTION_GET_LICENSE,
    LICENSE_REDUCE_SET_LICENSE,
    LICENSE_REDUCE_SELECT_LICENSE,
    LICENSE_ACTION_POST_LICENSE,
    LICENSE_ACTION_GET_LICENSE_BY_ID,
    CHECKOUT_REQUEST_LICENSE,
    LICENSE_REDUCE_IS_SHOW_WINDOW
   } from 'domain/constants/license.constant';
import { ILicense, LicenseActionTypes,LicenseReduceTypes } from 'domain/types/license.type';
import { IAddLicenseForm } from 'pages/views/private/license/addLicense';

export function getAllLicense(): LicenseActionTypes{
  return {
    type: LICENSE_ACTION_GET_LICENSE,
  }
}

export function getLicenseById(licenseId: string): LicenseActionTypes{
  return {
    type: LICENSE_ACTION_GET_LICENSE_BY_ID,
    payload: { licenseId }
  }
}

export function addLicense(values: IAddLicenseForm): LicenseActionTypes{
  return {
    type: LICENSE_ACTION_POST_LICENSE,
    payload: { values }
  };
}

export function setAllLicense(license: Array<ILicense> ): LicenseReduceTypes{
  return {
    type: LICENSE_REDUCE_SET_LICENSE,
    payload: { license }
  }
}

export function setLicense(license: ILicense | null ): LicenseReduceTypes{
  return {
    type: LICENSE_REDUCE_SELECT_LICENSE,
    payload: { license }
  }
}

export function showWindow(window: string, isShow: boolean ): LicenseReduceTypes{
  return {
    type: LICENSE_REDUCE_IS_SHOW_WINDOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}

export function reqLicense(isLoad: boolean): any{
  return {
    type: CHECKOUT_REQUEST_LICENSE,
    payload: { loading: isLoad }
  }
}

