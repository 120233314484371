import { 
    BID_REQUEST_ACTION_GET_BID_REQUEST, BID_REQUEST_REDUCE_SET_BID_REQUEST, CHECKOUT_REQUEST_ERROR_BID
   } from 'domain/constants/bid.constant'
  import { IBid, BidReduceTypes, BidActionTypes } from 'domain/types/bid.type';

export function getAllBidRequests(): BidActionTypes{
  return {
    type: BID_REQUEST_ACTION_GET_BID_REQUEST,
  }
}

export function setAllBidRequests(bids: Array<IBid> ): BidReduceTypes{
  return {
      type: BID_REQUEST_REDUCE_SET_BID_REQUEST,
      payload: { bids }
  }
}


export function reqBids(isLoad: boolean): any{
  return {
    type: CHECKOUT_REQUEST_ERROR_BID,
    payload: { loading: isLoad }
  }
}