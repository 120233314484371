import { combineReducers } from 'redux';
import auth from './auth.reduce';
import account from './account.reduce';
import license from './license.reduce';
import bids from './bid.reduce';

const rootReducer = combineReducers({
  auth,
  account,
  bids,
  license
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;