import React from 'react';

interface IEmptyProps{
    isEmpty: boolean;
    header: string;
    description : string;
    children: React.ReactNode
  }

export const EmptyPage: React.FC<IEmptyProps> = (props) => {
  const { isEmpty, header, description, children } = props;

  return (
    <>
    {
      isEmpty 
      ? (
        <div className='emptyPage'>

          <div className='emptyPage-description'>
            <h1>{header}</h1>
            <span>{description}</span>
          </div>
        </div>
      )
      : children
    }
    </>
  );
};