import React from 'react';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router';
import { signIn } from "domain/actions/auth.action";
import { Button } from 'primereact/button';

const Preview: React.FC = (props: any) => {
  const navigation = useNavigate();
  const { isAuthenticated, signIn} = props;

  const handleSingIn = () => {
    if(!isAuthenticated) return signIn()
    navigation('/main/license')
  };

  return (
    <div className="greeting-preview p-col-12 p-d-flex p-pr-0 p-pl-0 p-pt-4 p-pb-4">
      
      <div className="preview-authorization p-col-12 p-md-6 p-p-0 p-d-flex p-flex-column p-jc-between">
        <h1 className="text-success text-bold"> VD.QR-CODE - УДОБНОЕ ПРИЛОЖЕНИЕ ДЛЯ ПРОВЕРКИ QR-КОДА </h1>
        <p className="text-primary">Мы предлагаем удобное, современное приложение, для проверки QR кодов.</p>
        <div className="preview-auth--buttons p-col-12 p-p-0 p-d-flex p-flex-wrap">
          {!isAuthenticated && (
            <div className="p-col-12 p-md-5 p-pl-0 p-pr-0 p-pr-md-3" style={{minWidth: "188px"}}>
              <Button label="Зарегистрироваться" className="button-success p-col-12 p-p-0" />
            </div>
          )}
          
          <div className="p-col-12 p-md-7 p-pr-0 p-pl-0">
            <Button label="Войти в личный кабинет" className="button-success p-col-12 p-p-0" onClick={handleSingIn}/>
          </div>
        </div>
      </div>

      <div className="preview-image p-d-none p-d-md-flex p-md-6 p-p-0 p-text-center">
        <img src={process.env.PUBLIC_URL + "/images/qrcode.png"} alt="VDTransport" />
      </div>

    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {signIn} )(Preview);