export const AUTH_CHECKING = "AUTH_CHECKING";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_REJECT = "AUTH_REJECT";

export const AUTH_ACTION_GET_TOKEN = "AUTH_ACTION_GET_TOKEN";
export const AUTH_REDUCE_SET_AUTH = "AUTH_REDUCE_SET_AUTH";

export const AUTH_ACTION_GET_ACCOUNT_ME = "AUTH_ACTION_GET_ACCOUNT_ME";
export const AUTH_REDUCE_SET_ACCOUNT_ME = "AUTH_REDUCE_SET_ACCOUNT_ME";
export const AUTH_REDUCE_SET_CURRENT_USER = "AUTH_REDUCE_SET_CURRENT_USER";

export const CHECKOUT_REQUEST_ACCOUNT = "CHECKOUT_REQUEST_ACCOUNT";
export const CHECKOUT_REQUEST_ERROR_ACCOUNT = "CHECKOUT_REQUEST_ERROR_ACCOUNT";
export const CHECKOUT_REQUEST_SUCCESS_ACCOUNT = "CHECKOUT_REQUEST_SUCCESS_ACCOUNT";