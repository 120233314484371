import { AUTH_REDUCE_SET_AUTH } from "../constants/auth.constant";
import { load } from 'redux-localstorage-simple';
import { IAuthReduce } from "../types/auth";
import { AuthReduce } from 'domain/types/auth';
import { env } from "process";

const savedReduce: any = load();

const initialState = {
  isLoading: false,
  // isAuthenticated: (savedReduce && savedReduce.auth) ? savedReduce.auth.isAuthenticated : false
  isAuthenticated: false
};

export default function authReduce(state = initialState, action: AuthReduce): IAuthReduce {
  switch (action.type) {
    case AUTH_REDUCE_SET_AUTH:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isLoading: false
      };
    default:
      return state;
  }
}